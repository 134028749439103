(($) ->
  "use strict"
  ##global Response##
  ##global console##

  Homepage =
    init: ->
      # @ResponseCreate()
      @loadPage()
      # @scrollbarEffect()
      $('img.syr-footer-logo').lazyload()

    # ResponseCreate: ->
    #   Response.create
    #     prop: "width"
    #     prefix: "min-width- r src"
    #     breakpoints: [861,769,0]
    #     lazy: true

    responsiveH: ->
      # setTimeout ( ->
      windowHeight = $(window).height()
      windowWidth = $(window).width()
      # isMobile = windowWidth < 861
      isMobile = windowWidth < 768
      # isTablet = windowWidth < 961
      bannerContentHeight = $('#syr-top-banner-content').outerHeight()
      bannerImageHeight = $('.syr-index-top-banner-figure').outerHeight()      
      # sliderHeight = if isMobile then $('.syr-index-top-banner-figure').outerHeight() + $('#syr-top-banner-content').outerHeight() + $('#syr-index-watch-video').outerHeight() + $('.index-buy-tickets-btn').outerHeight() + 25 else Math.max(bannerContentHeight, bannerImageHeight)

      sliderHeight = if isMobile then $('.syr-index-top-banner-figure').outerHeight() + $('#syr-top-banner-content').outerHeight() + $('#syr-index-watch-video').outerHeight() + $('.index-buy-tickets-btn').outerHeight() + $('#top-video-box-figure').outerHeight() + 12 else 'auto'
      # console.log(Math.max(bannerContentHeight, bannerImageHeight))
      # console.log(bannerImageHeight)
      # console.log(bannerContentHeight)
      IndexbuyTicketsBtnWidth = if isMobile then windowWidth - 30 else 153
      # $('#syr-index-top-banner-shenyun').css
      #   height: sliderHeight  
      # $('#syr-index-top-banner-symphony').css
      #   height: sliderHeight
      # $('#syr-event-top-banner-symphony').css
      #   height: sliderHeight
      $('#syr-event-top-banner-shenyun').css
        height: sliderHeight
      $('.index-buy-tickets-btn').css
        width: IndexbuyTicketsBtnWidth

    dateHeightCalculate: ->
      $('.general-event-list-item .dates').each ->
        newHeight = $(this).next('.location-actions-wrapper').outerHeight()
        $(this).css
          height: newHeight

    loadPage: ->
      $ ->
        Homepage.responsiveH()
      $(window).on
        load: ->
          Homepage.responsiveH()
          Homepage.dateHeightCalculate()
        scroll: ->
          Homepage.responsiveH()
        resize: ->
          Homepage.responsiveH()
          Homepage.dateHeightCalculate()

      # Response.ready ->
      #   Homepage.responsiveH()
      # Response.resize ->
      #   Homepage.responsiveH()

    # scrollbarEffect: ->
    #   $('#syr-shows-near-you-wrapper').perfectScrollbar
    #     suppressScrollY: true
    





  




    
  $ ->
    Homepage.init()

) jQuery
