(function($) {
  "use strict";
  $.fn.quick_photo_ratio = function() {
    return this.each(function() {
      var aspectRatio, figure, figure_wrapper, h_origin, heightRatio, ratioOrigin, vertical, w_origin, wider, widthRatio, wrapper_h, wrapper_w;
      figure = $(this);
      figure_wrapper = figure.parent();
      wrapper_w = figure_wrapper.outerWidth();
      wrapper_h = figure_wrapper.outerHeight();
      w_origin = figure.outerWidth();
      h_origin = figure.outerHeight();
      heightRatio = wrapper_h / h_origin;
      widthRatio = wrapper_w / w_origin;
      aspectRatio = w_origin / h_origin;
      ratioOrigin = wrapper_w / wrapper_h;
      vertical = h_origin > w_origin;
      wider = aspectRatio > ratioOrigin;
      if (wider) {
        return figure.css({
          width: 'auto',
          height: wrapper_h,
          marginLeft: -(w_origin * heightRatio - wrapper_w) / 2,
          marginTop: 0
        });
      } else if (vertical) {
        return figure.css({
          height: wrapper_h,
          width: 'auto',
          marginTop: 0
        });
      } else {
        return figure.css({
          width: wrapper_w,
          height: 'auto',
          marginTop: -(h_origin * widthRatio - wrapper_h) / 2
        });
      }
    });
  };
  return $.fn.photoRatio = function() {
    var _this, fitRatio;
    _this = this;
    fitRatio = function() {
      return _this.each(function() {
        var aspectRatio, figure, figure_wrapper, h_origin, heightRatio, ratioOrigin, w_origin, wider, widthRatio, wrapper_h, wrapper_w;
        figure = $(this);
        figure_wrapper = figure.parent();
        wrapper_w = figure_wrapper.outerWidth();
        wrapper_h = figure_wrapper.outerHeight();
        w_origin = figure.width();
        h_origin = figure.height();
        heightRatio = wrapper_h / h_origin;
        widthRatio = wrapper_w / w_origin;
        aspectRatio = w_origin / h_origin;
        ratioOrigin = wrapper_w / wrapper_h;
        wider = aspectRatio > ratioOrigin;
        if (wider) {
          return figure.css({
            width: 'auto',
            height: wrapper_h,
            marginLeft: -(w_origin * heightRatio - wrapper_w) / 2
          });
        } else {
          return figure.css({
            width: wrapper_w,
            height: 'auto',
            marginTop: -(h_origin * widthRatio - wrapper_h) / 2
          });
        }
      });
    };
    $(window).on({
      load: function() {
        return fitRatio();
      },
      resize: function() {
        return fitRatio();
      },
      scroll: function() {
        return fitRatio();
      }
    });
    return $(function() {
      return fitRatio();
    });
  };
})(jQuery);

$(function() {
  var _scroll_to_anchor, buytickets_scroll, performance_scroll;
  _scroll_to_anchor = function(triggerBtn) {
    "use strict";
    return triggerBtn.bind('click', function(e) {
      var $anchor, positionY;
      e.preventDefault();
      $anchor = $(this);
      positionY = $($anchor.attr('href'));
      return $.scrollTo(positionY, 500, {
        offset: -20,
        easing: 'easeOutExpo'
      });
    });
  };
  performance_scroll = function() {
    var triggerBtn;
    triggerBtn = $('#full-order-summary-btn');
    return _scroll_to_anchor(triggerBtn);
  };
  performance_scroll();
  buytickets_scroll = function() {
    var triggerBtn;
    triggerBtn = $('.index-buy-tickets-btn');
    return _scroll_to_anchor(triggerBtn);
  };
  return buytickets_scroll();
});

(function($) {
  "use strict";
  var Homepage;
  Homepage = {
    init: function() {
      this.loadPage();
      return $('img.syr-footer-logo').lazyload();
    },
    responsiveH: function() {
      var IndexbuyTicketsBtnWidth, bannerContentHeight, bannerImageHeight, isMobile, sliderHeight, windowHeight, windowWidth;
      windowHeight = $(window).height();
      windowWidth = $(window).width();
      isMobile = windowWidth < 768;
      bannerContentHeight = $('#syr-top-banner-content').outerHeight();
      bannerImageHeight = $('.syr-index-top-banner-figure').outerHeight();
      sliderHeight = isMobile ? $('.syr-index-top-banner-figure').outerHeight() + $('#syr-top-banner-content').outerHeight() + $('#syr-index-watch-video').outerHeight() + $('.index-buy-tickets-btn').outerHeight() + $('#top-video-box-figure').outerHeight() + 12 : 'auto';
      IndexbuyTicketsBtnWidth = isMobile ? windowWidth - 30 : 153;
      $('#syr-event-top-banner-shenyun').css({
        height: sliderHeight
      });
      return $('.index-buy-tickets-btn').css({
        width: IndexbuyTicketsBtnWidth
      });
    },
    dateHeightCalculate: function() {
      return $('.general-event-list-item .dates').each(function() {
        var newHeight;
        newHeight = $(this).next('.location-actions-wrapper').outerHeight();
        return $(this).css({
          height: newHeight
        });
      });
    },
    loadPage: function() {
      $(function() {
        return Homepage.responsiveH();
      });
      return $(window).on({
        load: function() {
          Homepage.responsiveH();
          return Homepage.dateHeightCalculate();
        },
        scroll: function() {
          return Homepage.responsiveH();
        },
        resize: function() {
          Homepage.responsiveH();
          return Homepage.dateHeightCalculate();
        }
      });
    }
  };
  return $(function() {
    return Homepage.init();
  });
})(jQuery);

(function($) {
  "use strict";
  var MainAction;
  MainAction = {
    init: function() {
      var btn;
      this.lazyDiv();
      this.popupAction();
      $('.general-event-list-item-thumb img').photoRatio();
      this.toShowTextarea();
      this.focusActive();
      this.creatAcount();
      btn = $('.order-summary-share-btn');
      this.insertShareBtn(btn);
      return this.offCanvas();
    },
    lazyDiv: function() {
      $('.lazy').lazyload();
      return $('#syr-top-banner-content img').lazyload();
    },
    popupAction: function() {
      var iLightBoxSeting;
      $("[popup='ajax']").fancybox({
        type: 'ajax'
      });
      $("[popup='inline']").fancybox({
        type: 'inline'
      });
      $("[popup='image']").fancybox({
        type: 'image'
      });
      iLightBoxSeting = {
        skin: 'mac',
        opacity: 0.85,
        callback: {
          onOpen: function() {
            return $('.ilightbox-overlay').css('background-color', '#000');
          }
        }
      };
      $('#syr-index-watch-video').iLightBox(iLightBoxSeting);
      $('#top-video-box-figure').iLightBox(iLightBoxSeting);
      $('.right-col-video-trailer-thumb-a').iLightBox(iLightBoxSeting);
      $('.right-col-video-trailer-thumb-b').iLightBox(iLightBoxSeting);
      $('.right-col-video-trailer-thumb-c').iLightBox(iLightBoxSeting);
      return $('#shenyun-header-search-txt').iLightBox({
        type: 'inline',
        skin: 'mac',
        keepAspectRatio: false,
        overlay: {
          opacity: 0.95
        },
        callback: {
          onShow: function(api, position) {
            return setTimeout((function() {
              return $(api.currentElement).find('.shenyun-top-search-form-input').focus();
            }), 0);
          }
        }
      });
    },
    _toShowTextarea: function(btn) {
      return $.each(btn, function() {
        var theForm;
        theForm = $(this).next();
        return $(this).bind('click', function() {
          $(this).fadeOut(10);
          return theForm.fadeIn();
        });
      });
    },
    toShowTextarea: function() {
      var btn;
      btn = $('.order-customer-comments-title');
      return MainAction._toShowTextarea(btn);
    },
    focusActive: function() {
      var active_me, firstStep, firstStepVal, lastStep, lastStepVal, secondStep, secondStepVal;
      firstStep = $('#order-fast-payment-left-column');
      firstStepVal = firstStep.find('input');
      secondStep = $('#order-fast-payment-center-column');
      secondStepVal = secondStep.find('input, textarea');
      lastStep = $('#order-fast-payment-right-column');
      lastStepVal = lastStep.find('input, textarea, .sy-white-btn');
      active_me = function(focusDiv) {
        focusDiv.addClass('active');
        focusDiv.nextAll('.active-obj').removeClass('active');
        return focusDiv.prevAll('.active-obj').removeClass('active');
      };
      firstStepVal.bind({
        click: function() {
          var focusDiv;
          focusDiv = firstStep;
          return active_me(focusDiv);
        },
        focus: function() {
          var focusDiv;
          focusDiv = firstStep;
          return active_me(focusDiv);
        }
      });
      secondStepVal.bind({
        click: function() {
          var focusDiv;
          focusDiv = secondStep;
          return active_me(focusDiv);
        },
        focus: function() {
          var focusDiv;
          focusDiv = secondStep;
          return active_me(focusDiv);
        }
      });
      return lastStepVal.bind({
        click: function() {
          var focusDiv;
          focusDiv = lastStep;
          return active_me(focusDiv);
        },
        focus: function() {
          var focusDiv;
          focusDiv = lastStep;
          return active_me(focusDiv);
        }
      });
    },
    toggleDisplay: function(newForm, checkbox) {
      var _toggleDisplayForm;
      _toggleDisplayForm = function() {
        var eid;
        eid = checkbox.is(':checked');
        if (!eid) {
          return newForm.fadeOut();
        } else {
          return newForm.fadeIn();
        }
      };
      return checkbox.bind({
        click: function() {
          return _toggleDisplayForm();
        },
        focus: function() {
          return _toggleDisplayForm();
        }
      });
    },
    creatAcount: function() {
      var checkbox, newForm;
      newForm = $('#password-section');
      checkbox = $('#create-acc-for-later');
      return MainAction.toggleDisplay(newForm, checkbox);
    },
    insertShareBtn: function(btn) {
      return btn.each(function() {
        var paperText;
        paperText = $(this).parent('.tab-pane').find('.small-txt');
        return $(this).insertAfter(paperText);
      });
    },
    offCanvas: function() {
      return $('#tb-navbar-toggle').fancybox({
        padding: 0,
        margin: 0,
        closeBtn: false,
        scrolling: 'no',
        wrapCSS: 'off-canvas-menu',
        title: null,
        minWidth: '100%',
        minHeight: '100%',
        autoResize: true
      });
    }
  };
  return $(function() {
    return MainAction.init();
  });
})(jQuery);
