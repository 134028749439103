$ ->
  _scroll_to_anchor = (triggerBtn) ->
    "use strict"
    triggerBtn.bind 'click', (e) ->
      e.preventDefault()
      $anchor = $(this)
      positionY = $($anchor.attr('href'))
      $.scrollTo positionY, 500, offset: -20, easing: 'easeOutExpo'

  performance_scroll = ->
    triggerBtn = $('#full-order-summary-btn')
    _scroll_to_anchor(triggerBtn)
  performance_scroll()

  buytickets_scroll = ->
    triggerBtn = $('.index-buy-tickets-btn')
    _scroll_to_anchor(triggerBtn)
  buytickets_scroll()